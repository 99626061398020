import React from "react";

export default function Interview({for1, for2}) {
	return (
		<section className="bg-black w-full py-[67px] mb-[100px]">
			<div className={'container'}>
				<div className="grid grid-cols-2">
					<div
						className="bg-[url(./../images/speaking/border.svg)] bg-no-repeat pt-[42px] pb-[50px] pl-[37px] w-[353px] h-[295px] col-span-2 md:col-span-1 mx-auto mb-5 md:mb-0"
					>
						<p className="text-gradient font-lora text-2xl leading-[31px] font-normal">
							{for1?.title}
						</p>
						<p className="text-[18px] font-poppin font-normal leading-[23px]  text-white">
							{for1?.description}
							<br/>
							<a href={for1?.action?.url} className="text-[#34AFFE] leading-[27px]">
								{for1?.action?.text}
							</a>
						</p>
					</div>
					<div
						className="bg-[url(./../images/speaking/border.svg)] bg-no-repeat pt-[42px] pb-[50px] pl-[37px] w-[353px] h-[295px] col-span-2 md:col-span-1 mx-auto"
					>
						<p className="text-gradient text-2xl font-lora leading-[31px] font-normal">
							{for2?.title}
						</p>
						<p className="text-[18px] font-normal leading-[23px] font-poppin text-white">
							{for2?.description}
							<br/>
							<a href={for2?.action?.url} className="text-[#34AFFE] leading-[27px]">
								{for2?.action?.text}
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
