import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import PortableText from "react-portable-text";

export default function Nick({ aboutNickSection, sanitySpeaking }) {
  return (
    <div className="max-w-[1275px] mx-auto bg-[#F9F9F9] rounded-tl-[50px] rounded-tr-[50px] pb-[58px] pt-[117px] px-5 md:px-[50px] lg:px-[97px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-[65px]">
        <div className="relative">
          <GatsbyImage
            image={aboutNickSection?.thumbnail?.asset?.gatsbyImageData}
            alt="Nick"
            height={662}
          />
        </div>
        <div className="mt-14">
          <p className="font-lora text-[36px] leading-[46px] font-medium mb-[30px]">
            {aboutNickSection?.title}
          </p>
          <PortableText
            className="text-base leading-5 prose max-w-none"
            projectId="p5dzjrg7"
            dataset="production"
            content={aboutNickSection?._rawContent}
            serializers={{
              ul: ({ children }) => (
                <ul className="relative list-none">{children}</ul>
              ),
              li: ({ children }) => <li className="block">{children}</li>,
            }}
          />
        </div>
      </div>
      <div className="mt-[66.5px] max-w-[636px] mx-auto">
        <p className="font-lora text-[32px] leading-[40px] font-medium text-center mb-[33px]">
          Nick’s Core Message
        </p>
        <PortableText
          className="text-base leading-5 prose max-w-none"
          projectId="p5dzjrg7"
          dataset="production"
          content={sanitySpeaking?._rawMessage}
          serializers={{
            ul: ({ children }) => (
              <ul className="relative list-none">{children}</ul>
            ),
            li: ({ children }) => <li className="block">{children}</li>,
          }}
        />
      </div>
    </div>
  );
}
